/* font-family: 'Londrina Shadow', cursive;
font-family: 'Londrina Solid', cursive;
font-family: 'Nanum Myeongjo', serif;
font-family: 'Zen Loop', cursive; */
html, body, #root, .App {
  min-height: 100%;
  height: 1000px;
}

#app-container{
  height: 100%;
  position: relative;
}
#root {
  background: linear-gradient(
    to right,
    rgb(29, 137, 149) 13%,
    rgb(25, 80, 114) 34%,
    rgb(140, 63, 120) 61%,
    rgb(71, 48, 123) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  text-align: center;
}
ul {
  padding-left: 2rem;
  list-style-type: square;
}

.container-fluid{
  padding: 0;
}
.contact-link {
  color: white;
  font-size: 9rem;
  text-decoration: none;
  pointer-events: fill;
}

.contact-link:hover {
  color: black;
}

#code-pic {
  filter: blur(2px);
  -webkit-filter: blur(2px);
  pointer-events: none;
  height: 1000px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  display: flex;
  filter: opacity(0.2);
}

.landing-div {
  height: 100%;
}

#landing-bg {
  background: linear-gradient(
    -45deg,
    rgb(105, 56, 218),
    rgba(126, 36, 104, 1),
    rgba(25, 80, 114, 1),
    #0d4739
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100%;
  width: 100vw;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

h1 {
  font-family: "Londrina Shadow", cursive;
  font-size: 6rem;
  color: white;
  padding-top: 7%;
}

.App-header {
  font-family: "Londrina Shadow", cursive;
  font-size: 9rem;
  color: white;
  margin: 0 auto;
  padding-top: 15%;
}

.landing-p {
  font-family: "Zen Loop", cursive;
  font-size: 4rem;
  color: white;
}

#me-pic {
  filter: drop-shadow(1px 2px 3px black);
  width: 20%;
}

.hidden-proj-text {
  position: absolute;
  bottom: 550px;
  left: 158px;
  font-size: 2rem;
  color: black;
}

/* Projects */

.proj-desc-bub {
  background: rgba(80, 227, 194, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 70%;
  margin: 1% auto;
}
#app-link-div {
  margin: 0% auto;
  align-content: center;
  width: 35%;
}
#api-link {
  text-decoration: none;
  font-size: 1.2rem;
  margin: 0% auto;
  width: 40%;
  color: white;
  background: rgba(13, 12, 13, 0.3);
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 2px;
}

.desc-h4 {
  width: 20%;
  text-align: center;
  margin: 1% auto;
}

.h5-anchor {
  text-decoration: none;
  color: black;
}

.inline-link {
  text-decoration: none;
  font-weight: bold;
  color: black;
}

.project-pic {
  width: 50%;
  border: black;
  border-style: groove;
  border-radius: 160px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

.project-card {
  margin: 10% auto;
  font-family: "Nanum Myeongjo", serif;
  border: 2px solid black;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  min-height: 18vh;
}

.card-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.card-text {
  font-size: 1rem;
  line-height: normal;
  text-align: justify;
}

.project-pic:hover {
  border-color: white;
  border-style: solid;
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
}

.section-div {
  min-height: 96vh;
}

.logo-container {
  margin: 3% auto;
}

.exp-card img {
  cursor: pointer;
}

.card-body h5 {
  width: 175px;
  margin: auto;
}

/* Baby Book Page */

#babybook-gif {
  width: 90%;
  margin-top: 3%;
  border: solid 4px #94b5c0;
}

#bb-visit {
  background-color: #94b5c0;
  color: white;
  border-color: white;
  border-radius: 20px;
  border: solid 4px white;
  font-size: 1.5rem;
}

#bb-visit:hover {
  background-color: white;
  color: #94b5c0;
  border-color: #94b5c0;
}

.logo-img {
  width: 5%;
}

.list-group {
  padding: 3% 0;
}

.list-group-item {
  background-color: transparent;
  color: black;
  text-align: justify;
  font-size: 1.1rem;
  border: none;
  font-family: "Nanum Myeongjo", serif;
}

.btn-primary {
  font-family: "Nanum Myeongjo", serif;
}

.fa-check-circle {
  font-size: 0.7rem;
  vertical-align: middle;
}

ul.list-group {
  list-style-type: square;
}

/* Experience Section */

.exp-card {
  margin: 3% 5%;
  font-family: "Nanum Myeongjo", serif;
  font-size: 0.5rem;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  min-height: 240px;
  align-items: center;
}

#pca-pic {
  width: 40%;
  margin: 3% auto 4%;
}

#meketa-pic {
  width: 35%;
  margin: 5% auto;
}

#wrapmate-logo {
  width: 45%;
  margin: 5% auto 10% auto;
}

#code-icon {
  width: 16%;
  margin: 3% auto;
}

h5,
h6 {
  font-weight: bold;
}

.expand-icon {
  font-size: 2rem;
  color: #84888b;
}

#PCA,
#Meketa,
#softDev,
#wrapmateBtn {
  background-color: transparent;
  border: none;
}

/* .card-body {
  position: absolute;
  top: 4rem;
  width: 15rem;
} */

/* ABOUT ME */

#hike-pic {
  width: 20%;
  filter: drop-shadow(1px 2px 3px black);
}

.fa-angles-down{
  height: 3em;
  color: white;
  margin-bottom: 2rem;
}

.about-me-text .list-group-item {
  font-family: "Nanum Myeongjo", serif;
  font-size: 1rem;
  margin: 0 2%;
  line-height: normal;
  background-color: white;
  color: black;
}

.contact-button {
  padding: 5%;
  color: white;
  font-family: "Zen Loop", cursive;
  font-size: 2.5rem;
  font-weight: bold;
  background: rgba(75, 222, 234, 0.6);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.contact-button:hover {
  background-color: transparent;
  color: black;
  border-color: white;
  border-style: solid;
}

.contact-button a {
  color: white;
  text-decoration: none;
}

.contact-btns {
  margin: 5% auto;
}

#me-card {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 40vw;
  margin: auto;
  margin-bottom: 2rem;
}

#portland{
  border-radius: 15px;
}

.about-me-img{
  width: 20vw;
  margin: auto;
  border-radius: 15px;
  margin-bottom: 2rem;
}

#ku{
  width: 20vw;
  margin: auto;
}

/* Nav Bar */

.current-nav {
  text-decoration: overline;
  text-decoration-color: turquoise;
  text-decoration-style: double;
}
.dropdown-menu {
  background-color: black;
}

.dropdown-item {
  color: white;
  text-decoration: none !important;
  font-family: "Nanum Myeongjo", serif;
}

.dropdown-divider {
  color: white;
}

.navbar {
  background-color: transparent !important;
  font-family: "Londrina Solid", cursive;
}

.navbar-brand {
  font-size: 2.5rem;
  color: white !important;
}

.navbar-brand:hover {
  color: black !important;
}

.proj-bull {
  font-size: 0.4rem;
  vertical-align: middle;
}

.navbar-light .navbar-toggler{
  color: white;
  border: none;
}

@media screen and (max-width: 769px) {
  .full-nav {
    display: none;
  }
  #app-link-div {
    width: 47%;
  }
  .desc-h4 {
    margin: 3% auto;
  }
}

@media screen and (min-width: 769px) {
  .navbar-narrow {
    display: none;
  }
}

@media screen and (min-width: 1600px) {
#code-pic{
  display: none;
}
}

.navbar-narrow {
  margin: 3%;
  position: absolute;
}

.menu-btn {
  background-color: #5c636a;
}

/* showfinder page */

#showfinder-div {
  min-height: 100%;
  background-color: #3492dc;
}

#sf-visit {
  background-color: #94b5c0;
  color: white;
  border-color: white;
  border-radius: 20px;
  border: solid 4px white;
  font-size: 1.5rem;
}

.proj-headers {
  padding-top: 7%;
}

#sf-gif {
  width: 90%;
  margin-top: 3%;
  border: solid 4px #94b5c0;
}

/* Mello Page*/

#mello-div {
  min-height: 100%;
  background-color: #479495;
}

/* Project Pages */
.site-gif {
  width: 30%;
  margin: 3% auto;
  border: solid 4px black;
  pointer-events: all;
}
.full-gif-div {
  height: 98vh;
}

.btn-div {
  padding-top: 10%;
}

.full-screen-gif {
  width: 70vw;
  height: 70vh;
  margin: 1% auto;
}

.site-visit {
  padding: 6px;
  font-size: 1.3rem;
  margin-top: 5px;
  background: rgba(248, 248, 248, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: black;
}

.site-visit:hover,
.code-visit:hover {
  background-color: transparent;
  color: black;
  border-color: black;
}

.code-visit {
  padding: 6px;
  font-size: 1.3rem;
  margin-left: 2%;
  margin-top: 5px;
  background: rgba(248, 248, 248, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: black;
}

.proj-cont {
  margin-top: 5%;
  text-align: left;
  font-family: "Nanum Myeongjo", serif;
}

.gif-div {
  text-align: center;
}

.proj-divider {
  color: black;
}

.proj-div {
  min-height: 100%;
  background-color: #76758f;
}

/* footer */

#footer-div {
  height: 6vh;
  background: linear-gradient( to right, rgb(8, 143, 120) 13%, rgb(25, 80, 114) 34%, rgb(126, 36, 104) 61%, rgb(71, 48, 123) 100% );
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}
#footer {
  font-family: "Nanum Myeongjo", serif;
  margin: auto;
  width: 100vw;
  color: white;
}

/* For Mobile */

@media screen and (max-width: 769px) {

  html, body, #root, .App {
    height: 1000px;
  }

  .section-div {
    min-height: 96vh;
    height: unset !important;
  }

  #background-img{
    display: none;
  }
  .App-header {
    font-size: 5rem;
  }

  .landing-p {
    font-size: 3rem;
    max-width: 300px;
    margin: auto;
    padding-top: 1.5rem;
    line-height: 65px;
  }

  .col{
    padding: 1rem 0;
  }

  .contact-link {
    font-size: 8rem;
    margin-top: 2%;
  }

  .col {
    flex: none;
  }

  .project-card {
    display: none;
  }

  .project-pic {
    width: 50vw !important;
    margin: 2vh 10vh;
  }

  .exp-card {
    font-size: 0.4em;
    width: 80vw;
    margin: auto;
    text-align: center;
  }

  #me-pic {
    display: none;
  }
  #me-card {
    width: 75vw;
  }

  #hike-pic {
    display: none;
  }

  .about-me-img{
    width: 75vw;
  }

  #ku{
    width: 60vw;
    margin: auto;
  }

  .contact-button {
    margin: 3% auto;
    width: 90vw;
  }

  #exp-header {
    font-size: 5rem;
    color: white;
    padding-top: 3rem;
  }

  #meketa-pic {
    margin: 1% auto;
  }

  .logo-img {
    width: 10% !important;
  }
  /* .site-gif {
    width: 85% !important;
  } */
  .full-screen-gif {
    height: 40vh !important;
    margin: 30% auto !important;
  }
  .desc-h4 {
    width: 50% !important;
    text-align: center !important;
    margin: 5% auto !important;
  }
  .proj-desc-bub {
    width: 100% !important;
  }
  #app-link-div {
    width: 102% !important;
  }
}
/* Mobile End */

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
